/**
 * @flow
 */
import React from "react"
import styled from "styled-components"

import Label from "../label"
import Image from "../image"

import media from "../../../styles/media"

type Props = {
  year: number,
  link_name: string,
  link_url: string,
  image: string,
}

const Container = styled.div`
  width: calc((80vw * 0.75));
  .cover {
    width: 100%;
    height: auto;
    margin-bottom: 0.5rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-family: "AvenirLTStdBlack";
      font-size: 0.8rem;
    }
  }
  ${media.tablet`
    width: calc((75vw / 4) - (80px / 4));
  `}
`

const Slide = ({ image, year, link_name, link_url, ...props }: Props) => {
  return (
    <Container {...props}>
      <div className="cover">{image && <Image src={image} />}</div>
      <div className="meta">
        <span> {year} </span>
        <Label
          fontSize={0.8}
          innerRotate={-1}
          outerRotate={0}
          url={link_url}
          value={link_name}
          className="mouse-link"
        />
      </div>
    </Container>
  )
}

export default Slide
