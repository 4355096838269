import React from "react"
import styled from "styled-components"

import Image from "../../elements/image"

import Name from "./name"

import media from "../../../styles/media"

const Container = styled.div`
  background-color: rgba(0, 93, 107, 0.99);
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-template-rows: auto auto;
  grid-template-areas: "name name" "photo story";
  overflow: hidden;
`

const NameContainer = styled.div`
  position: relative;
  grid-area: name;
  overflow: hidden;
  padding-bottom: 4rem;
  ${media.tablet`
    padding-bottom: 6rem;
  `}
`

const Photo = styled.div`
  grid-area: photo;
  position: relative;
  transform: scale(1.5);
  transform-origin: top left;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 93, 107, 0.99);
    mix-blend-mode: lighten;
  }
`

const Story = styled.div`
  position: relative;
  grid-area: story;
  padding-bottom: 4rem;
  h4 {
    padding: 0 5vw 0 0;
    font-size: 1.25rem;
    margin-bottom: 2rem;
    line-height: 1.25;
  }
  p {
    padding: 0 10vw 0 0;
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  ${media.tablet`
    padding-bottom: 6rem;
    h4 {
      padding: 0 5vw 0 0;
      font-size: 1.85rem;
    }
    p {
      padding: 0 20vw 0 0;
      font-size: 1.25rem;
      line-height: 1.75;
    }
  `}
`

const Biography = ({ ...props }) => {
  return (
    <Container {...props}>
      <Photo>
        <Image
          src="RyanHolidayPage/ryan_holiday.png"
          alt="Sound Idea Sessions | Black Coffee"
        />
      </Photo>
      <NameContainer name="biography">
        <Name />
      </NameContainer>
      <Story>
        <h4>
          Ryan Holiday is a young, smart, marketing
          powerhouse-turned-motivational-speaker who has attracted fans from
          every imaginable discipline.
        </h4>
        <p>
          When it comes to motivation, Holiday makes readers—and listeners—take
          stock of their lives and careers through the timeless lens of ancient
          philosophy, stoicism. His book The Daily Stoic distils the ancient
          wisdom of the stoic philosophers into 366 practical meditations; each
          meant to enrich and enliven our daily lives—at work, on the field, and
          in our relationships. In Ego Is the Enemy—an instant Wall Street
          Journal, USA Today, and an international bestseller—he explains how
          “the battle against ego must be fought on many fronts,” and provides
          helpful examples of major figures who’ve achieved success by eschewing
          the spotlight and putting the greater good above their own vanity. And
          in the now cult-classic The Obstacle Is the Way, he shows us how to
          turn even the most insurmountable obstacles into advantages, inspired
          by an ageless set of philosophical principles used by icons from John
          D. Rockefeller to Amelia Earhart, Richard Wright to Steve Jobs. Forbes
          calls it “an inspiring read for anyone faced with adversity” that can
          “save years of future angst.”
        </p>
        <p>
          Publishers Weekly argues that “Holiday’s performance is commanding and
          optimistic, sure to inspire readers to take new perspective on their
          apparent obstacles.” It has so far been translated into over 30
          languages and has sold over 400,000 copies. In total, his books have
          sold 1.3 million copies. But Holiday is also a premier marketing
          speaker, and the renowned media strategist behind celebrity authors
          Tucker Max and Timothy Ferriss. The former Director of Marketing at
          American Apparel, his campaigns have been studied by YouTube, Twitter,
          and Google. After dropping out of college at 19 to apprentice under
          the strategist Robert Greene (The 48 Laws of Power), he went on to
          advise many bestselling authors and multi-platinum musicians. His
          latest book marks a resounding return to the world of marketing and
          publicity. Perennial Seller: The Art of Making and Marketing Work That
          Lasts explores iconic products, artworks, artists that achieve
          longevity in our disposable age. To The Financial Times, it outlines
          “how to create lasting success in a world of flash-in-the-pan hits and
          how to extend the proverbial 15 minutes of fame to a decade or even a
          century.” It’s a book Inc. says, “every entrepreneur should read” and
          an FT book of the month selection.
        </p>
        <p>
          His book Growth Hacker Marketing: A Primer on the Future of PR,
          Marketing, and Advertising explains how multibillion-dollar brands are
          built without spending a dime on ‘traditional marketing,’ and frames
          the overnight success of companies like Dropbox, Facebook, AirBnb, and
          Twitter. Porter Gale, Former VP of Marketing at Virgin America, says:
          “This book is a wakeup call for every marketing exec in the business.
          And a tutorial for engineers, IT, founders and designers. Read it.”
          Holiday is a media columnist for The New York Observer, and his other
          writings—published in 28 languages—appear in Forbes, The Huffington
          Post, Fast Company, Thought Catalog, and The Columbia Journalism
          Review, among others. Holiday’s first book, Trust Me, I’m Lying:
          Confessions of a Media Manipulator, a tell-all expose of modern online
          journalism, is a Wall Street Journal bestseller and an Amazon Editor’s
          Best Book of the Month. The Financial Times called it an “astonishing,
          disturbing book.” To The Big Picture author Edward J. Epstein, “Ryan
          Holiday’s brilliant expose of the unreality of the Internet should be
          required reading for every thinker in America.” His seventh book,
          Conspiracy, has been optioned for a film.
        </p>
      </Story>
    </Container>
  )
}

export default Biography
