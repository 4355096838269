/**
 * @flow
 */
import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { Swiper } from "swiper/dist/js/swiper.esm"

import Slide from "./slide"

type Props = {
  content: Array<Object>,
}

const Container = styled.div``

const Slider = ({ content }: Props) => {
  const swiperElement = useRef(null)
  const swiper = useRef(null)

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      speed: 750,
      slidesPerView: "auto",
      spaceBetween: 80,
      breakpointsInverse: true,
      touchStartPreventDefault: false,
      breakpoints: {},
    })
    return () => {
      if (swiper.current) {
        swiper.current.destroy()
      }
    }
  }, [])

  const renderSlides = () => {
    return content.map((item, i) => {
      return (
        <Slide
          key={i}
          className="swiper-slide"
          year={item.year}
          link_name={item.link_name}
          link_url={item.link_url}
          image={item.image}
        />
      )
    })
  }
  return (
    <Container>
      <div ref={swiperElement} className="swiper-container">
        <div className="swiper-wrapper"> {renderSlides()} </div>
      </div>
    </Container>
  )
}

Slider.defaultProps = {
  content: [
    {
      year: "2012",
      link_name: "Buy this book",
      link_url: "https://www.soundideasessions.com",
    },
    {
      year: "2013",
      link_name: "Buy this book",
      link_url: "https://www.soundideasessions.com",
    },
    {
      year: "2014",
      link_name: "Buy this book",
      link_url: "https://www.soundideasessions.com",
    },
    {
      year: "2015",
      link_name: "Buy this book",
      link_url: "https://www.soundideasessions.com",
    },
    {
      year: "2016",
      link_name: "Buy this book",
      link_url: "https://www.soundideasessions.com",
    },
    {
      year: "2017",
      link_name: "Buy this book",
      link_url: "https://www.soundideasessions.com",
    },
  ],
}

export default Slider

/*
  have the height of the img push the height of the slider
  overall responsive styling alignment for the page as well
  get tickets link
  get ticket padding

  perfec the page

  perfect both bios
  perfect about

  deconstruct landing

*/
