import React from "react"
import styled from "styled-components"

import Label from "../../elements/label"
import Slider from "../../elements/Slider"
// import BuyLink from "../../elements/BuyLink"

import channel from "./channel.png"

import media from "../../../styles/media"

import content from "./content"

const Container = styled.div`
  background-color: rgba(63, 63, 63, 0.99);
  position: relative;
  padding: 5vh 0 5vh 0;
  ${media.tablet`
    padding: 10vh 0 10vh 0;
  `}
`

const Header = styled.div`
  padding-left: 10vw;
  padding-right: var(--gutter-s);
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 1.5rem;
  }
  img {
    height: 30px;
    width: auto;
  }
  ${media.tablet`
    padding-left: 25vw;
    justify-content: flex-start;
    margin-bottom: 4rem;
    h2 {
      font-size: 2rem;
      margin-right: 4rem;
    }
  `}
`

const SliderContainer = styled.div`
  margin-bottom: 2rem;
  .swiper-container {
    padding-left: 10vw;
  }
  ${media.tablet`
    margin-bottom: 4rem;
    .swiper-container {
      padding-left: 25vw;
    }

  `}
`

const Meta = styled.div`
  padding-left: 10vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas: "title" "social-links" "buy-link";
  grid-gap: 1rem 0;
  h2 {
    grid-area: title;
    font-size: 1.5rem;
  }
  .social-links {
    grid-area: social-links;
  }
  .buy-link {
    grid-area: buy-link;
  }
  ${media.tablet`
    padding-left: 25vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "title ." "social-links buy-link";
    h2 {
      font-size: 2rem;
    }
    .social-links {
      align-self: center;
    }
    .buy-link {
      justify-self: flex-end;
      align-self: center;
      padding-right: var(--gutter-l);
    }
  `}
`

const Bibliography = ({ ...props }) => {
  return (
    <Container {...props}>
      <Header>
        <h2>Bibliography</h2>
        <a
          href="https://www.nytimes.com/books/best-sellers"
          target="_blank"
          rel="noreferrer noopener"
          className="mouse-link"
        >
          <img src={channel} alt="Sound Idea Sessions" />
        </a>
      </Header>
      <SliderContainer>
        <Slider content={content} />
      </SliderContainer>
      <Meta>
        <h2>Social</h2>
        <div className="social-links">
          <Label
            fontScaled
            fontSize={0.8}
            mRight={1}
            outerRotate={0}
            innerRotate={-1}
            value="Instagram"
            url="https://www.instagram.com/ryanholiday"
            className="mouse-link"
          />
          <Label
            fontScaled
            fontSize={0.8}
            mRight={1}
            outerRotate={0}
            innerRotate={-1}
            value="Twitter"
            url="https://twitter.com/RyanHoliday"
            className="mouse-link"
          />
          <Label
            fontScaled
            fontSize={0.8}
            mRight={1}
            outerRotate={0}
            innerRotate={-1}
            value="Facebook"
            url="https://www.facebook.com/ryanholiday"
            className="mouse-link"
          />
        </div>
        {/* <div className="buy-link">
          <BuyLink />
        </div> */}
      </Meta>
    </Container>
  )
}

export default Bibliography
