import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import RyanHolidayPage from "../components/RyanHolidayPage"

const RyanHoliday = () => {
  const menuItems = [
    { name: "biography", scrollLink: true },
    { name: "bibliography", scrollLink: true },
  ]
  return (
    <>
      <SEO title="Ryan Holiday" />
      <Navbar back items={menuItems} topOffset={0} bottomOffset={125} />
      <RyanHolidayPage />
      <Footer back items={menuItems} />
    </>
  )
}

export default RyanHoliday
