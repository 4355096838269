const content = [
  {
    year: "2019",
    link_name: "Buy this book",
    link_url: "https://www.exclusivebooks.co.za/product/9781788162050",
    image: "RyanHolidayPage/books/rh-book-7.jpg",
  },
  {
    year: "2018",
    link_name: "Buy this book",
    link_url: "https://www.exclusivebooks.co.za/product/9780735217645",
    image: "RyanHolidayPage/books/rh-book-6.jpg",
  },
  {
    year: "2016",
    link_name: "Buy this book",
    link_url: "https://www.exclusivebooks.co.za/product/9781781257654",
    image: "RyanHolidayPage/books/rh-book-5.jpg",
  },
  {
    year: "2016",
    link_name: "Buy this book",
    link_url: "https://www.exclusivebooks.co.za/product/9781591847816",
    image: "RyanHolidayPage/books/rh-book-4.jpg",
  },
  {
    year: "2014",
    link_name: "Buy this book",
    link_url: "https://www.exclusivebooks.co.za/product/9781781251492",
    image: "RyanHolidayPage/books/rh-book-3.jpg",
  },
  {
    year: "2013",
    link_name: "Buy this book",
    link_url: "https://www.exclusivebooks.co.za/product/9781781254363",
    image: "RyanHolidayPage/books/rh-book-2.jpg",
  },
  {
    year: "2012",
    link_name: "Buy this book",
    link_url: "https://www.exclusivebooks.co.za/product/9781788160063",
    image: "RyanHolidayPage/books/rh-book-1.jpg",
  },
]

export default content
