import React from "react"
import styled from "styled-components"

import Biography from "./Biography"
import Bibliography from "./Bibliography"

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
`

const RyanHolidayPage = () => {
  return (
    <Container>
      <Biography name="biography" />
      <Bibliography name="bibliography" />
    </Container>
  )
}

export default RyanHolidayPage
