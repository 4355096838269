import React from "react"
import styled from "styled-components"

const Container = styled.div`
  transform: rotate(-1deg);
  svg {
    enable-background: new 0 0 1013.6 278.8;
  }
`

const Name = () => {
  return (
    <Container>
      <svg x="0px" y="0px" viewBox="0 0 1013.6 278.8">
        <g>
          <path
            d="M93,109.8h-3.8v56.1H0V7.8h170.3c37.3,0,58,18.3,58,51.1c0,25.2-11.3,41.1-33.8,47.7l45.9,59.2H131.7L93,109.8z
          M137.2,58.1c0-12.7-10-20.4-21.5-20.4H89.2v40.6h26.5C128.2,78.3,137.2,71.2,137.2,58.1z"
          />
          <path d="M330.8,109.3L245.6,5.8h109.2l21.4,33.3l21.2-33.3h109.2l-85.2,103.5v57.2h-90.5V109.3z" />
          <path d="M611.2,138.1l-7.5,21.7h-87.4L572.5,1.3h139.9l56,158.5h-87.2l-7.7-21.7H611.2z M661.1,103l-18.8-53.5L623.5,103H661.1z" />
          <path d="M914.2,60.2V0h84.2v157.3h-86.5l-51.4-67.4v67.4h-84.2V0h88.4L914.2,60.2z" />
          <path d="M98.5,238.8H69.9v39.8H11.6V175.3h58.3v39.2h28.6v-39.2h58.3v103.2H98.5V238.8z" />
          <path
            d="M248.8,169.9c19.5,0,33.6,0.8,42.5,2.3c14.3,2.6,23.8,8.7,28.3,18.5c2.9,6.4,4.2,17.6,4.2,33.6c0,16.1-1.4,27.2-4.2,33.6
          c-4.5,9.8-14,15.9-28.3,18.5c-8.8,1.5-23,2.3-42.5,2.3c-19.6,0-33.8-0.8-42.6-2.3c-14.3-2.6-23.8-8.7-28.3-18.5
          c-2.9-6.4-4.2-17.6-4.2-33.6c0-16.1,1.4-27.2,4.2-33.6c4.5-9.8,14-15.9,28.3-18.5C215.1,170.7,229.2,169.9,248.8,169.9z M248.8,260
          c6.9,0,11.6-2.7,14-8.2c2-4.5,3-13.8,3-27.8c0-14.8-1-24.4-3-28.3c-2.3-4.6-7.1-6.9-14-6.9s-11.6,2.3-14,6.9
          c-1.9,3.7-2.9,13.1-2.9,28.3c0,14.3,1,23.6,2.9,27.8C237.3,257.3,241.9,260,248.8,260z"
          />
          <path d="M390.8,172.3V249h51.6v26.6H332.7V172.3H390.8z" />
          <path d="M448.3,274.5V171.3h58.1v103.2H448.3z" />
          <path
            d="M589.3,169.3c3.4,0,9.1-0.1,17.2-0.3c5.6-0.1,9.9,0,13.1,0.1c4.2,0.1,8.4,0.5,12.4,1.2c14.3,2.5,23.8,8.4,28.3,17.8
          c2.9,6,4.2,16.8,4.2,32.1c0,15.4-1.4,26.1-4.2,32.1c-4.5,9.4-14,15.4-28.3,17.8c-8.6,1.5-22.9,2.2-42.6,2.2h-72.4V169.3H589.3z
          M603.9,243c1.4-3.4,2-11.2,2-23.3c0-8.8-0.8-16.5-2.5-22.6c-0.5-2-2-3.9-4.4-5.6c-2.7-1.8-5.9-2.7-9.3-2.7H575v64.3h14.8
          C596.6,253.1,601.3,249.6,603.9,243z"
          />
          <path d="M737.1,256.4l-4.9,14.2h-56.9l36.6-103.2H803l36.5,103.2h-56.8l-5-14.2H737.1z M769.7,233.5l-12.3-34.9l-12.3,34.9H769.7z" />
          <path d="M900.7,231.8L846,165.3h70.1l13.8,21.4l13.6-21.4h70.1l-54.7,66.5v36.8h-58.1V231.8z" />
        </g>
      </svg>
    </Container>
  )
}

export default Name
